body{-webkit-font-smoothing:antialiased;-moz-osx-font-smoothing:grayscale}
.svg-funnel-js{font-family:'Poppins', sans-serif}
.svg-funnel-js__container{width:100%;height:100%}
.svg-funnel-js__labels{width:100%;box-sizing:border-box}
.svg-funnel-js__labels .svg-funnel-js__label{flex:1 1;position:relative}
.label__value{font-size:24px;color:#fff;line-height:18px;margin-bottom:6px}
.label__title{font-family: 'Poppins'; font-size:12px;font-weight:700;color:#006064}
.label__percentage{font-size:16px;font-weight:700;color:#f05d61; font-family: 'Poppins';}
.label__segment-percentages{position:absolute;top:50%;-webkit-transform:translateY(-50%);transform:translateY(-50%);width:100%;left:0;padding:8px 24px;box-sizing:border-box;background-color:rgba(0,54,58,.8);opacity:0;-webkit-transition:opacity .1s ease;transition:opacity .1s ease;cursor:default}
.label__segment-percentages ul{margin:0;padding:0;list-style-type:none}
.label__segment-percentages ul li{font-size:11px;line-height:16px;color:#fff;margin:18px 0; font-family: 'Poppins'}
.label__segment-percentages ul li .percentage__list-label{font-weight:700;color:#f05d61;font-family: 'Poppins';}
.svg-funnel-js .svg-funnel-js__labels .svg-funnel-js__label:hover .label__segment-percentages{opacity:1}
.svg-funnel-js:not(.svg-funnel-js--vertical){padding-top:64px;padding-bottom:16px}
.svg-funnel-js:not(.svg-funnel-js--vertical) .svg-funnel-js__label{padding-left:24px}
.svg-funnel-js:not(.svg-funnel-js--vertical) .svg-funnel-js__label:not(:first-child){border-left:1px solid #f05d61}
.svg-funnel-js.svg-funnel-js--vertical{padding-left:16px;padding-right:16px}
.svg-funnel-js__subLabel--color{width:12px;height:12px;border-radius:50%;margin:2px 8px 2px 0; margin-top:20px;} 
.svg-funnel-js__subLabel--color{display: table;margin: 0 auto;} 
.svg-funnel-js__subLabels{
  text-align: center;
  position: absolute;
  left: 0;
  right: 0;
}
.label__segment-percentages{margin-top:0;margin-left:106px;width:calc(100% - 106px)}
.segment-percentage__list{display:flex;justify-content:space-around}
.svg-funnel-js__subLabel{display:inline-block; margin-right: 25px; font-size:11px;color:#fff;line-height:16px;margin-top:20px;}
.svg-funnel-js{display:inline-block;position:relative}
.svg-funnel-js svg{display:block}
.svg-funnel-js__label{border-bottom: 1px solid #f05d61;}
.svg-funnel-js .svg-funnel-js__labels{position:absolute;display:flex;width:100%;height:100%;top:0;left:0}
.svg-funnel-js.svg-funnel-js--vertical .svg-funnel-js__labels{flex-direction:column}
.funnel {
  box-sizing: border-box;
  margin-top: 15px;
  width: 100%
}

.label__value,
.svg-funnel-js__subLabel--title {
  color: #000 !important;
  font-weight: 500;
  font-family: 'Poppins';
  margin-top: 5px;
}
@media only screen and (max-width: 800px) {
  .label__segment-percentages{display: none;}
}
.DateRangePickerInput__withBorder {
  border-top: none !important;
  border-left: none !important;
  border-right: none !important;
  border-bottom: 1px solid rgba(0, 0, 0, 0.42) !important;
  -webkit-transition: border-bottom-color 200ms cubic-bezier(0.4, 0, 0.2, 1) 0ms !important;
  transition: border-bottom-color 200ms cubic-bezier(0.4, 0, 0.2, 1) 0ms !important;
  line-height: 1.1875em !important;
  padding: 0 !important;
  width: 200px;
}
.DateInput_input {
  font-weight: 200;
  font-size: 17px;
  line-height: 24px;
  color: #484848;
  background-color: #fff;
  width: 100%;
  padding: 5px 0px 5px 2px;
  border: 0;
  border-top: 0;
  border-right: 0;
  border-bottom: 2px solid transparent;
  border-left: 0;
  border-radius: 0;
  font-family: 'Poppins';
}

.DateInput {
  margin: 0;
  padding: 0;
  background: #fff;
  position: relative;
  display: inline-block;
  width: 88px;
  vertical-align: middle
}
