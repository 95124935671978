.DateRangePickerInput__withBorder {
  border-top: none !important;
  border-left: none !important;
  border-right: none !important;
  border-bottom: 1px solid rgba(0, 0, 0, 0.42) !important;
  transition: border-bottom-color 200ms cubic-bezier(0.4, 0, 0.2, 1) 0ms !important;
  line-height: 1.1875em !important;
  padding: 0 !important;
  width: 200px;
}
.DateInput_input {
  font-weight: 200;
  font-size: 17px;
  line-height: 24px;
  color: #484848;
  background-color: #fff;
  width: 100%;
  padding: 5px 0px 5px 2px;
  border: 0;
  border-top: 0;
  border-right: 0;
  border-bottom: 2px solid transparent;
  border-left: 0;
  border-radius: 0;
  font-family: 'Poppins';
}

.DateInput {
  margin: 0;
  padding: 0;
  background: #fff;
  position: relative;
  display: inline-block;
  width: 88px;
  vertical-align: middle
}